export const BaseUrl = "https://mytheron-dev-backend.citpl.info";
// const apiUrl = process.env.MYTHERON_API_URL
// console.log("typeurl", apiUrl)
export const LOGIN_API_URL = `${BaseUrl}/auth/login`;
export const REFRESH_API_URL = `${BaseUrl}/auth/refresh`;
export const LOGIN_AUTH_URL = `${BaseUrl}/auth`;
export const RTH_API_URL = `${BaseUrl}/master`;
export const PROFILE_API_URL = `${BaseUrl}/profile`;
export const FILE_API_URL = `${BaseUrl}/rth`;
// return https://mytheron-dev-backend.citpl.info/profile/updateProfilePicture?id=66c85f91fb2208588ff3dc72
 
// https://mytheron-dev-backend.citpl.info/auth/sendMail?email=praveenkumarganesan96%40gmail.com
// 'https://mytheron-dev-backend.citpl.info/dashboard/get