import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, Alert } from "@mui/material";
import { useDispatch } from 'react-redux';
import { getRthBusinessInputData, submitRthForm } from '../../../../redux/features/rthSlice';
import CommonSelect from "../../../common/CommomSelect";
import { SelectChangeEvent } from "@mui/material";
import CommonTextField from "../../../common/CommonTextField";
import type { AppDispatch } from '../../../../redux/store';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import {
  getRthBandData, 
  getRthDepartmentData,
  getRthLocationData, 
  getRthL1ApproverData, 
  getRthL2ApproverData, 
  getRthPositionReportsTo, 
  getRthPrimarySkillsData, 
  // getRthPriorityData, 
  getRthQualificationData, 
  getRthSecondarySkillsData, 
} from "../../../../Crud/rth";

interface SkillOption {
  value: string;
  label: string;
}



export const useAppDispatch: () => AppDispatch = useDispatch;

interface FormData {
  userId: string;
  contractDur: string;
  contractType: string;
  businessId: string;
  positionTitle: string;
  l1ApprovalId: string;
  l2ApprovalId: string;
  departmentId: string;
  band: string;
  qualNeeded: string;
  minExperience: string;
  maxExperience: string;
  primarySkills?: any;
  secondarySkills?: any;
  workMode: string;
  priority: string;
  numOfPosition: string;
  posReportsToId: string;
  reasonForHire: string;
  location: string;
  ctc: string;
  prefStartDate: string;
  comments: string;
}

const arr: any = [];
const secondarySkills: string[] = [];
const ContractTab: React.FC = () => {
 
 
  
  const [LocationData, setLocationData] = useState([])
  const [posReportsTo, setposReportsTo] = useState([])
  const [ QualificationData, setQualificationData] = useState([])
  // const [PriorityData,setPriorityData] = useState([])
  const [L2ApproverData,setL2ApproverData] = useState([])
  const [L1ApproverData,setL1ApproverData] = useState([])
  const [secondarySkillsData,setSecondarySkillsData] = useState([])
  const [primarySkillsData,setPrimarySkillsData] = useState([])
  
  const [departmentData,setDepartmentData] = useState([])
  const [bandData,setBandData] = useState([])
  const [businessData, setBusinessData] = useState([])
  const [pSkills,setPSkills] = useState<any>([]);
  const [sSkills,setSSkills] = useState<any>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [submitted, setSubmitted] = useState(false);

  const token: any = localStorage.getItem("token")
  const decoded: any = jwtDecode(token);
  const userId = decoded.userId;

 
  const [formData, setFormData] = useState<FormData>({
     userId: userId || "",
    contractDur: "",
    contractType: "",
    businessId: "",
    positionTitle: "",
    l1ApprovalId: "",
    l2ApprovalId: "",
    departmentId: "",
    band: "",
    qualNeeded: "",
    minExperience: "",
    maxExperience: "",
    primarySkills: "",
    secondarySkills: "",
    workMode: "",
    priority: "",
    numOfPosition: "",
    posReportsToId: "",
    reasonForHire: "",
    location: "",
    ctc: "",
    prefStartDate: "",
    comments: "",
  });

  useEffect(() => {
    getRthBusinessInputData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.businessId, label: i.businessName } })
      setBusinessData(data)
     // console.log('data', data)
    }).catch((err) => console.error(err));
    
    getRthBandData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.bandId, label: i.bandName } })
      setBandData(data)
     // console.log('data', data)
    }).catch((err) => console.error(err));

    getRthDepartmentData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.departmentId, label: i.departmentName } })
      setDepartmentData(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));

    // getRthWorkModeData().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.workId, label: i.workName } })
    //   setWorkModeData(data)
    //  // console.log('data', data)
    // }).catch((err) => console.error(err));

    getRthPrimarySkillsData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.id, label: i.skillName } })
      setPrimarySkillsData(data)
      // console.log('data', data)
    }).catch((err) => console.error(err));

    getRthSecondarySkillsData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.id, label: i.skillName } })
      setSecondarySkillsData(data)
      // console.log('data', data)
    }).catch((err) => console.error(err));

    getRthL1ApproverData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.id, label: i.firstName } })
      setL1ApproverData(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));

    getRthL2ApproverData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.id, label: i.firstName } })
      setL2ApproverData(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));

    // getRthPriorityData().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.priorityId, label: i.priorityName } })
    //   setPriorityData(data)
    //   //console.log('data', data)
    // }).catch((err) => console.error(err));

    getRthQualificationData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.qualificationId, label: i.degree } })
      setQualificationData(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));
     
    getRthPositionReportsTo().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.id, label: i.firstName } })
      setposReportsTo(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));

    getRthLocationData().then(res => {
      const data = res.data?.map((i: any) => { return { value: i.locationId, label: i.locationName } })
      setLocationData(data)
      //console.log('data', data)
    }).catch((err) => console.error(err));

    // getRthReasonForHire().then(res => {
    //   const data = res.data?.map((i: any) => { return { value: i.reasonOfHireId, label: i.reasonOfHireName } })
    //   setReasonForHire(data)
    //   //console.log('data', data)
    // }).catch((err) => console.error(err));
    
  }, []);

  const reasonForHireOptions = [
    
    { value: 'replacement', label: 'Replacement' },
    { value: 'newPosition', label: 'New Position' },
    { value: 'expansion', label: 'Expansion' },
  ];

  const workModeOptions = [
    
    { value: 'onsite', label: 'Onsite' },
    { value: 'remote', label: 'Remote' },
    { value: 'hybrid', label: 'Hybrid' },
  ];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    console.log("change", name, value);

    if (name === "secondarySkills") {
      secondarySkills.push(value);
     
    }

    if (name === "primarySkills") {
      arr.push(value);
      console.log("arr", arr)
      setFormData({
        ...formData,
        primarySkills: [arr],
      });
      console.log("....formData", formData);
    }


    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key as keyof typeof formData]) {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   const newErrors = validate();
  //   if (Object.keys(newErrors).length > 0) {
  //     setErrors(newErrors);
  //     return;
  //   }
  //   setSubmitted(true);
  //   // Form is valid, proceed with submission
  // };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitted(true);
    console.log("arr", arr)

    let data = {
      ...formData
    }
    if (pSkills.length>=1) {
      data = {
        ...data,
        primarySkills: pSkills
      }
    }
    if (setSSkills.length>=1) {
      data = {
        ...data,
        secondarySkills: sSkills
      }
    }
    
    // console.log("formData111", data)


    if (validate()) {
      await submitRthForm(data);
      toast.success('Form submitted successfully!');
      setFormData({
        userId: userId || "",
    contractDur: "",
    contractType: "",
    businessId: "",
    positionTitle: "",
    l1ApprovalId: "",
    l2ApprovalId: "",
    departmentId: "",
    band: "",
    qualNeeded: "",
    minExperience: "",
    maxExperience: "",
    primarySkills: "", 
    secondarySkills: "",
    workMode: "",
    priority: "",
    numOfPosition: "",
    posReportsToId: "",
    reasonForHire: "",
    location: "",
    ctc: "",
    prefStartDate: "",
    comments: "",
    });
      setErrors({});
      setSubmitted(false);
    }
    // const newErrors = validate();
    // setErrors(newErrors);
    // if (Object.keys(newErrors).length === 0) {
    //   setSubmitted(true);
    // } else {
    //   setSubmitted(false);
    // }
  };

  return (
    <>
     
      <Box component="form" onSubmit={handleSubmit}>
        <Box
          sx={{
            p: 3,
            backgroundColor: "white",
            marginTop: "10px",
            minWidth: "103%",
          }}
        >
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                md={2}
                sx={{ paddingTop: "33px", paddingLeft: "30px" }}
              >
                <CommonSelect
                  labelId="business-label"
                  label="Business"
                  name="businessId"
                  value={formData.businessId}
                  onChange={handleChange}
                  options={businessData}
                  error={!!errors.businessId}
                  helperText={errors.businessId}
                />
              </Grid>
              <Grid item xs={12} md={2}>
              <CommonTextField
                  label="Position Title"
                  name="positionTitle"
                  value={formData.positionTitle.replace(/[^a-zA-Z\s]/g, '')}
                  onChange={handleChange}
                  error={!!errors.positionTitle}
                  helperText={errors.positionTitle}
                 
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                 label="Number of Positions"
                 name="numOfPosition"
                 value={formData.numOfPosition.replace(/[^0-9]/g, '')}
                 onChange={handleChange}
                 error={!!errors.numOfPosition}
                 helperText={errors.numOfPosition}
               />
              </Grid>
              <Grid item xs={12} md={2}>
              <CommonSelect
                   labelId="pos-reports-to-label"
                   label="Position Reports To"
                   name="posReportsTo"
                   value={formData.posReportsToId}
                   onChange={handleChange}
                   error={!!errors.posReportsToId}
                   helperText={errors.posReportsToId}
                   options={posReportsTo}
                 />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="l1-approval-label"
                  label="L1 Approval"
                  name="l1ApprovalId"
                  value={formData.l1ApprovalId}
                  onChange={handleChange}
                  options={L1ApproverData}
                  error={!!errors.l1ApprovalId}
                  helperText={errors.l1ApprovalId}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="l2-approval-label"
                  label="L2 Approval"
                  name="l2ApprovalId"
                  value={formData.l2ApprovalId}
                  onChange={handleChange}
                  options={L2ApproverData}
                  error={!!errors.l2ApprovalId}
                  helperText={errors.l2ApprovalId}
                />
              </Grid>
              <Grid item xs={12} md={2}>
              <CommonSelect
                  labelId="reason-for-hire"
                  label="Reason for Hire"
                  name="reasonForHire"
                  value={formData.reasonForHire}
                  onChange={handleChange}
                  error={!!errors.reasonForHire}
                  helperText={errors.reasonForHire}
                  options={reasonForHireOptions}
                />
              </Grid>
              <Grid item xs={12} md={2}>
              <CommonSelect
                  labelId="location"
                  label="Location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  error={!!errors.location}
                  helperText={errors.location}
                  options={LocationData}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Department"
                  label="Department"
                  name="departmentId"
                  value={formData.departmentId}
                  onChange={handleChange}
                  options={departmentData}
                  error={!!errors.departmentId}
                  helperText={errors.departmentId}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Band"
                  label="Band"
                  name="band"
                  value={formData.band}
                  onChange={handleChange}
                  options={bandData}
                  error={!!errors.band}
                  helperText={errors.band}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Qualification "
                  label="Qualification "
                  name="qualNeeded"
                  value={formData.qualNeeded}
                  onChange={handleChange}
                  options={QualificationData}
                  error={!!errors.qualNeeded}
                  helperText={errors.qualNeeded}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Min Experience"
                  label="Min Experience"
                  name="minExperience"
                  value={formData.minExperience}
                  onChange={handleChange}
                  options={[
                    { value: "1 year", label: "1 year" },
                    { value: "2 years", label: "2 years" },
                  ]}
                  error={!!errors.minExperience}
                  helperText={errors.minExperience}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Max Experience"
                  label="Max Experience"
                  name="maxExperience"
                  value={formData.maxExperience}
                  onChange={handleChange}
                  options={[
                    { value: "5 years", label: "5 years" },
                    { value: "10 years", label: "10 years" },
                  ]}
                  error={!!errors.maxExperience}
                  helperText={errors.maxExperience}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="CTC"
                  name="ctc"
                  value={formData.ctc.replace(/[^0-9]/g, '')}
                  onChange={handleChange}
                  error={!!errors.ctc}
                  helperText={errors.ctc}
                />
              </Grid>
              <Grid item xs={12} md={2}>
              <Autocomplete
                  size="small"
                  multiple
                  limitTags={1}
                  options={primarySkillsData}
                  getOptionLabel={(option: SkillOption) => option.label}
                  onChange={(event, newValue) => {
                    const pData = newValue.map(i=>i.value)
                    setPSkills(pData)
                    const sSkills = secondarySkillsData.filter((i:any)=>!pData.includes(i.value))
                    
                    if(newValue.length>=1){
                      setSecondarySkillsData(sSkills)
                    }else{
                      setSecondarySkillsData(primarySkillsData)
                    }
                    setFormData((prevData) => ({
                      ...prevData,
                      primarySkills: pData,
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      primarySkills: "",
                    }));
                  }}

                    renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Primary Skills"
                      error={!!errors.primarySkills}
                      helperText={errors.primarySkills}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: '0.875rem', 
                          paddingLeft:'2px',
                           paddingRight:'2px',
                           margin:'0px',
                         
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: '0.875rem', 
                        },
                      }}
                     />
                  )}
                  disableCloseOnSelect
                  renderOption={(props, option: SkillOption, { selected }) => (
                    <li {...props}
                    style={{
                      fontSize: '0.875rem', 
                      padding: '1px 1px', 
                      margin: '2px 0', 
                    }}
                    >
                      <Checkbox
                        sx={{
                          transform: 'scale(0.8)', 
                          marginRight: 2,
                          padding: 0,
                        }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
              <Autocomplete
                  size="small"
                  multiple
                  limitTags={1}
                  options={secondarySkillsData}
                  getOptionLabel={(option: SkillOption) => option.label}
                  onChange={(event, newValue) => {
                    const sData = newValue.map(i=>i.value)
                    setSSkills(sData);
                    
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      secondarySkills: "",
                    }));
                    setFormData((prevData) => ({
                      ...prevData,
                      secondarySkills: sData
                    }));
                  }}
                   
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Secondary Skills"
                      error={!!errors.secondarySkills}
                      helperText={errors.secondarySkills}
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          fontSize: '0.875rem', 
                          paddingLeft:'2px',
                           paddingRight:'2px',
                           margin:'0px',
                         
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: '0.875rem', 
                        },
                      }}
                     


                    />
                  )}
                  disableCloseOnSelect
                  renderOption={(props, option: SkillOption, { selected }) => (
                    <li {...props}
                    style={{
                      fontSize: '0.875rem', 
                      padding: '1px 1px', 
                      margin: '2px 0', 
                    }}
                    >
                      <Checkbox
                         sx={{
                          transform: 'scale(0.8)', 
                          marginRight: 2,
                          padding: 0,
                        }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonTextField
                  label="Preferred Start Date"
                  type="date"
                  name="prefStartDate"
                  value={formData.prefStartDate}
                  onChange={handleChange}
                  error={!!errors.prefStartDate}
                  helperText={errors.prefStartDate}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="work-mode-label"
                  label="Work Mode"
                  name="workMode"
                  value={formData.workMode}
                  onChange={handleChange}
                  options={workModeOptions}
                  error={!!errors.workMode}
                  helperText={errors.workMode}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="priority-label"
                  label="Priority"
                  name="priority"
                  value={formData.priority}
                  onChange={handleChange}
                  options={[
                    { value: "High", label: "High" },
                    { value: "Medium", label: "Medium" },
                    { value: "Low", label: "Low" },
                  ]}
                  error={!!errors.priority}
                  helperText={errors.priority}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CommonTextField
                  label="Comments"
                  name="comments"
                  value={formData.comments}
                  onChange={handleChange}
                  error={!!errors.comments}
                  helperText={errors.comments}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            p: 3,
            backgroundColor: "white",
            marginTop: "10px",
            minWidth: "103%",
          }}
        >
          <Typography color="gray">Contract Details</Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Contract Duration"
                  label="Contract Duration"
                  name="contractDur"
                  value={formData.contractDur}
                  onChange={handleChange}
                  options={[
                    { value: "3 month", label: "3 month" },
                    { value: "6 month", label: "6 month" },
                  ]}
                  error={!!errors.contractDur}
                  helperText={errors.contractDur}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CommonSelect
                  labelId="Contract Type"
                  label="Contract Type"
                  name="contractType"
                  value={formData.contractType}
                  onChange={handleChange}
                  options={[
                    { value: "position 1", label: "position 1" },
                    { value: "position 2", label: "position 2" },
                  ]}
                  error={!!errors.contractType}
                  helperText={errors.contractType}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "flex-end",
            width: "106%",
          }}
        >
          <Button
            variant="contained"
            sx={{
              mr: 2,
              color: "black",
              backgroundColor: "transparent",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
            onClick={() => {
              setFormData({
                
                userId: userId || "",
                contractDur: "",
                contractType: "",
                businessId: "",
                positionTitle: "",
                l1ApprovalId: "",
                l2ApprovalId: "",
                departmentId: "",
                band: "",
                qualNeeded: "",
                minExperience: "",
                maxExperience: "",
                primarySkills: [],
                secondarySkills: [],
                workMode: "",
                priority: "",
                numOfPosition: "",
                posReportsToId: "",
                reasonForHire: "",
                location: "",
                ctc: "",
                prefStartDate: "",
                comments: "",
              });
              setErrors({});
              setSubmitted(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="contained" sx={{ color: "#F0F5FF" }} type="submit">
            Submit
          </Button>
        </Box>
        {submitted && Object.keys(errors).length > 0 && (
        <Alert severity="error" sx={{ mt: 2 }}>
          Please correct the errors in the form before submitting.
        </Alert>
      )}
      </Box>
    </>
  );
};

export default ContractTab;
