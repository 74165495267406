import React from 'react';
import { Box, Typography } from '@mui/material';
import JobDetails from './JobDetails';
import JobFooter from './JobFooter';
import JobProgress from './JobProgress';
import JobTime from './JobTime';
import JobHeader from './JobHeader';
import { Job } from '../../redux/features/jobcardSlice';
import closedImg from '../../assets/images/closedtext.svg'
import ApprovalActions from './jobApprovel';
import ToBeAssigned from './tobeAssigned';
import { useNavigate } from 'react-router-dom';

interface JobCardProps {
  job?: Job;
  userName: string;
  userTitle: string;
  userAvatar: string;
}

const JobCard: React.FC<JobCardProps> = ({ job, userName, userTitle, userAvatar }) => {

  const navigate=useNavigate();

  const handleApprove = () => {
    // Handle approve action
    console.log('Approved:', job?.id);
  };

  const handleDecline = () => {
    // Handle decline action
    console.log('Declined:', job?.id);
  };

  const handleBoxClick=()=>{
    navigate(`/uxdesign`)
  }



return(
  
  <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: 1, position: 'relative' }} onClick={handleBoxClick}>
     <Box sx={{
    backgroundColor: job?.status === "Closed"
      ? "#F6F6F6"
      : job?.level === "L1"
      ? "#F2ECFA"
      : job?.level === "L2"
      ? "#EAF5FE"
      : "white",
    borderRadius: "10px",
    border: job?.level === "L1"
      ? "2px solid #5708B8"
      : job?.level === "L2"
      ? "2px solid #36CFC9"
      : "1px solid lightgray",
    boxShadow: job?.level === "L1" || job?.level === "L2"
      ? "0px 4px 10px rgba(0, 0, 0, 0.1)"
      : "none",
    width: "218px",
    padding: "7px",
    cursor:'pointer'    
  }}>

      <JobHeader department={job?.positionTitle} priority={job?.priority} business={job?.business} />

      <JobDetails typeOfRth={job?.typeOfRth} modeOfRth={job?.modeOfRth} rthProcess={job?.rthProcess} status={job?.status} />

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ width: "100%" }}>
          <JobTime posted={job?.posted} />
          {job?.status === "waitingForApprovel" && (
              <ApprovalActions
                onApprove={handleApprove}
                onDecline={handleDecline}
              />
            )}
            {job?.status === "tobeAssigned" && (
              <ToBeAssigned
                
              />
            )}
          {(job?.status === "Closed" || job?.status === "In Progress") ?
            <Box>
              <Box sx={{ display: "flex", marginTop: "20px", paddingLeft: "2px" }}>
                <Box>
                  <img src={job?.image} style={{ width: "25px", height: "25px" }} alt="jobcardimage" />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, marginTop: "2px" }}>
                  <Typography variant='body2' sx={{ fontSize: '10px', lineHeight: 1.2 }}>{job?.name}</Typography>
                  <Typography variant='caption' sx={{ fontSize: '8px', lineHeight: 1.2, color: 'gray' }}>{job?.reqName}</Typography>
                </Box>
              </Box>
              {job?.status === "Closed" && (
                <Box sx={{ position: "absolute", top: 35, right: 15 }}>
                  <img src={closedImg} style={{ height: "95px" }} alt="close" />
                </Box>
              )}
            </Box>
            :
            <>
              {job?.tabStatus === "open" ?
                <JobFooter userName={userName} userTitle={userTitle} userAvatar={userAvatar} status={job?.status}/>
                :
                <>
                {(job?.status === "TBA" || job?.status === "Waiting for the L1&L2 Approvals") && (
                  <JobProgress L1Status={job?.l1Status} L2Status={job?.l2Status} />)}
                  <JobFooter userName={userName} userTitle={userTitle} userAvatar={userAvatar} status={job?.status} />
                </>
              }
            </>
          }
        </Box>
      </Box>
    </Box>
  </Box>
)};

export default JobCard;
