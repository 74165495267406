import { configureStore } from '@reduxjs/toolkit'
import authSlice from '../features/authSlice'
import userReducer from '../features/userSlice'
import profileReducer from '../features/profileSlice'
import passwordReducer from '../features/passwordSlice';
import jobReducer from '../features/jobcardSlice';

export const store = configureStore({
  reducer: {
   authLogin : authSlice,
   user: userReducer,
   profile: profileReducer,
   password: passwordReducer,
   jobs: jobReducer,
  },
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch