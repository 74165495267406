import React from 'react';
import { Box,Typography } from '@mui/material';
import priorityHighSymbol from '../../assets/images/priorityHighSymbol.svg'
import priorityLowSymbol from '../../assets/images/priorityLowSymbol.svg'
import priorityMediumSymbol from '../../assets/images/priorityMediumSymbol.svg'

interface JobHeaderProps {
  department?: string;
  business?:{
    businessId:string,
    businessName:string
  };
  priority?:string
}

const JobHeader: React.FC<JobHeaderProps> = ({ department, business,priority }) => {
  
  return (

    <Box sx={{ display: "flex", justifyContent: 'space-between', paddingTop: '5px', margin: "0 5px" }} gap={1}>      
      
      <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{department}</Typography>


      <Typography gap={1} sx={{ fontWeight: 'bold', fontSize: '10px',color:(priority === 'High')? 'red':(priority === 'Medium') ? 'yellow':'green' }}>{priority === 'High'? <img src={priorityHighSymbol} alt="" /> : priority === 'Medium' ? <img src={priorityMediumSymbol} alt="" />  : <img src={priorityLowSymbol} alt="" />  }{" "+priority}</Typography>       
      <Typography sx={{ fontSize: '8px', color: '#08979C', padding: "3px 6px 0px 6px", backgroundColor: `${business?.businessName === "HEPL" ? "#D3ADF7" : '#B5F5EC'}`, borderRadius: '1px' }}>{business?.businessName}</Typography>
    </Box>
  );
}

export default JobHeader;
