import React from 'react';
import { Box } from '@mui/material';
import JobCard from '../common/JobCard'
import { Job } from '../../redux/features/jobcardSlice';

export interface ViewCardProps {
  data: Job[]; 
}

const ViewCard: React.FC<ViewCardProps> = ({ data }) => (

  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0px' }}>
    {data.map((job, index) => (
      <JobCard
        key={index}
        job={job}
        userName="Nithya Shree"
        userTitle="Senior Recruiter"
        userAvatar="https://mui.com/static/images/avatar/3.jpg"
      />
      
    ))}
  </Box>
);

export default ViewCard;
