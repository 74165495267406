import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import SearchBar from './SearchBar';
import ViewCard from '../ViewCard';
import FilterSidebar from './FilterSidebar';
import { useNavigate } from 'react-router-dom';
import WFA from '../Rth/rthtable/WFA';
import RTHInProgressTable from '../Rth/rthtable/RthInProgressTable';
import RTHClosedTable from '../Rth/rthtable/RthClosedTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { fetchJobs } from '../../../redux/features/jobcardSlice';
import { jwtDecode } from "jwt-decode";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} pt={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

interface RoleTabItem {
  label: string;
  component: JSX.Element;
  data?: any;
}

interface RoleTabs {
  admin: RoleTabItem[];
  user: RoleTabItem[];
  [key: string]: RoleTabItem[]; // To support other roles if needed
}

export const TabsPanelNew: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [viewType, setViewType] = useState<'grid' | 'list'>('grid');
  const [userRole] = useState('admin');
  const token: any = localStorage.getItem("token");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { myRequest, inProgress, closed, loading, error } = useSelector(
    (state: RootState) => state.jobs
  );

  const decoded: any = jwtDecode(token);
  const userId = decoded.userId;

  console.log(myRequest)
  const handleViewChange = (view: 'grid' | 'list') => {
    setViewType(view);
  };

  const handleButtonClick = () => {
    navigate('/newrth'); 
  };

  useEffect(() => {
    dispatch(fetchJobs(userId));
  }, [dispatch, userId]);

  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;


  // Example: Fetch user role dynamically (could be from API, state, etc.)
   // Default role is 'admin'

  // Static data for testing
  // const waitingForL1L2 = [{ id: 1, name: 'Request 1', status: "waitingForL1L2", department: "Full Stack Developer", business:"CITPL", typeOfRth:"Internal", modeOfRth:"on Role", rthProcess:"move to job" },
  //   { id: 2, name: 'Request 1', status: "waitingForL1L2", department: "Full Stack Developer", business:"CITPL", typeOfRth:"Internal", modeOfRth:"on Role", rthProcess:"move to job" },
  //   { id: 3, name: 'Request 1', status: "waitingForL1L2", department: "Full Stack Developer", business:"CITPL", typeOfRth:"Internal", modeOfRth:"on Role", rthProcess:"move to job" },
  //   { id: 4, name: 'Request 1', status: "waitingForL1L2", department: "Full Stack Developer", business:"CITPL", typeOfRth:"Internal", modeOfRth:"on Role", rthProcess:"move to job" },
  //   { id: 5, name: 'Request 1', status: "waitingForL1L2", department: "Full Stack Developer", business:"CITPL", typeOfRth:"Internal", modeOfRth:"on Role", rthProcess:"move to job" }
  // ];
  // const inProgress = [{ id: 2, name: 'Request 3', status: "In Progress", department: "Full Stack Developer", business:"CITPL", typeOfRth:"Internal", modeOfRth:"on Role", rthProcess:"move to job" }];
  // const closed = [{ id: 3, name: 'Request 5', status: "Closed" , department: "Full Stack Developer", business:"CITPL", typeOfRth:"Internal", modeOfRth:"on Role", rthProcess:"move to job"}];
  // const waitingForApprovel = [{ id: 4, name: 'Request 1', status: "waitingForApprovel", department: "Full Stack Developer", business:"CITPL", typeOfRth:"Internal", modeOfRth:"on Role", rthProcess:"move to job", level:"L1"},
  //    {id: 5, name: 'Request 1', status: "waitingForApprovel", department: "Full Stack Developer", business:"CITPL", typeOfRth:"Internal", modeOfRth:"on Role", rthProcess:"move to job", level:"L2"}
  //  ];
  //  const tobeAssigned = [{ id: 6, name: 'Request 5', status: "tobeAssigned" , department: "Full Stack Developer", business:"CITPL", typeOfRth:"Internal", modeOfRth:"on Role", rthProcess:"move to job"}];

  

  

  // Define the structure for roleTabs
  const roleTabs: RoleTabs = {
    admin: [
      { label: 'My Requests', component: <WFA data={myRequest} />, data: myRequest },
      { label: 'In Progress', component: <RTHInProgressTable data={inProgress} />, data: inProgress },
      { label: 'Closed', component: <RTHClosedTable data={closed} />, data: closed },
      // { label: 'waitingForApprovel', component: <WFA data={myRequest} />, data: myRequest },
      // { label: 'To Be Assigned', component: <WFA data={waitingForApprovel} />, data: tobeAssigned },
    ],
    user: [
      { label: 'My Requests', component: <WFA data={myRequest} /> , data: myRequest },
      { label: 'In Progress', component: <RTHInProgressTable data={inProgress} /> },
      { label: 'Closed', component: <RTHClosedTable data={closed} /> },
    ],
  };

  // Function to get tabs based on the role
  const getTabsByRole = (role: string): RoleTabItem[] => {
    return roleTabs[role] || [];
  };

  const tabs = getTabsByRole(userRole); // Dynamically get tabs based on role

  return (
    <Box sx={{ width: '95%', backgroundColor: '#f0f4ff', display: 'flex', position: 'relative' }} >
        
      <FilterSidebar onChangeFilter={setDrawerOpen} drawerOpen={drawerOpen} />
      <Box sx={{ width: '100%', transition: 'margin-left 0.3s' }}>
      <Box>
        <Paper elevation={0} sx={{ backgroundColor: 'transparent' }}>
          <Tabs
            value={tabIndex}
            onChange={(e, newIndex) => setTabIndex(newIndex)}
            indicatorColor="primary"
            aria-label="job tabs"
            sx={{ ml: drawerOpen ? '0px' : '100px' }}
            TabIndicatorProps={{
              style: {
                width: '20px',
                marginLeft: `${tabIndex === 0 ? '45px' : '36px'}`,
              },
            }}
            
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                {...a11yProps(index)}
                sx={{ fontSize: '13px', textTransform: 'none' }}
              />
            ))}
          </Tabs>
        </Paper>
        <SearchBar viewType={viewType} handleViewChange={handleViewChange} handleButtonClick={handleButtonClick} />

        {tabs.map((tab, index) => (
          <CustomTabPanel value={tabIndex} index={index} key={index}>
            {viewType === 'grid' ? (
              <Grid container spacing={2} xs={16} sx={{ display: 'flex', flexWrap: 'wrap', gap: '0px' }}>
                <ViewCard data={tab.data} />
              </Grid>
            ) : (
              tab.component
            )}
          </CustomTabPanel>
        ))}
      </Box>
      </Box>
    </Box>
  );
};
