import React from "react";
import { Box, Table, TableContainer, Chip, Paper, Button, TableBody, IconButton, TableHead, TableRow } from '@mui/material';
import { StyledTableCell } from './RthClosedTable';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import pendingIcon from '../../../../assets/images/pendingicon.svg';
import { ViewCardProps } from "../../ViewCard";

  
const WFA: React.FC <ViewCardProps>= ({ data }) => {
  return (
    <Box>
      {/* <SearchBar /> */}
      <Box sx={{ width: "100%", overflowX: "auto", mt: 2 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="RTH table">
            <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
              <TableRow>
                <StyledTableCell padding="checkbox">
                  <input type="checkbox" />
                </StyledTableCell>
                <StyledTableCell>SI.No.</StyledTableCell>
                <StyledTableCell>
                  RTH Name
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  Type of RTH
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  Business
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  Priority
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  Status
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  L1 Status
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  L2 Status
                  <ArrowUpwardIcon
                    fontSize="inherit"
                    sx={{ ml: 0.5, color: "#CDCDCD" }}
                  />
                </StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row.rthId}>
                  <StyledTableCell padding="checkbox">
                    <input type="checkbox" />
                  </StyledTableCell>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>{row.department}</StyledTableCell>
                  <StyledTableCell>
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      <Button
                        size="small"
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: "#F0F0F0",
                          border: "1px solid lightgray",
                          fontSize: "8px",
                          padding: "2px 4px",
                          color: "#8C8C8C",
                          height: "20px",
                          minWidth: "50px",
                        }}
                      >
                        Internal
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: "#F0F0F0",
                          border: "1px solid lightgray",
                          fontSize: "8px",
                          padding: "2px 4px",
                          color: "#8C8C8C",
                          height: "20px",
                          minWidth: "50px",
                        }}
                      >
                        On Role
                      </Button>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Chip
                      label={row.business}
                      size="small"
                      sx={{
                        fontSize: "12px",
                        padding: "3px 6px 0px 6px",
                        height: "px",
                        borderRadius: "1px",
                        backgroundColor:
                          row.business === "HEPL" ? "#E0E7FF" : "#E0F2FE",
                        color: row.business === "HEPL" ? "#3730A3" : "#075985",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "1px" }}
                    >
                      <SignalCellularAltIcon
                        fontSize="small"
                        sx={{ color: "red" }}
                      />
                      <Chip
                        label={row.priority}
                        size="small"
                        variant="outlined"
                        sx={{
                          fontSize: "10px",
                          color: "red",
                          borderColor: "transparent",
                          backgroundColor: "transparent",
                          marginTop: "6px",
                          paddingRight: "0px",
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Chip
                      label={row.status}
                      size="small"
                      sx={{
                        fontSize: "12px",
                        padding: "3px 6px 0px 6px",
                        height: "px",
                        borderRadius: "1px",
                        backgroundColor: "#389E0D 10%",
                        color: "#389E0D",
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.l1Status === "Approved" ? (
                      <Chip
                        label={row.l1Status}
                        icon={
                          <CheckCircleIcon
                            style={{ color: "#4caf50", fontSize: "12px" }}
                          />
                        }
                        size="small"
                        sx={{ backgroundColor: "#DCFCE7", color: "#166534" }}
                      />
                    ) : (
                      <Chip
                        label={row.l1Status}
                        icon={
                          <img
                            src={pendingIcon}
                            alt="pending"
                            style={{ width: "10px", height: "10px" }}
                          />
                        }
                        size="small"
                        sx={{ backgroundColor: "#FEF3C7", color: "#92400E" }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.l2Status === "Pending" ? (
                      <Chip
                        label={row.l2Status}
                        icon={
                          <img
                            src={pendingIcon}
                            alt="pending"
                            style={{ width: "10px", height: "10px" }}
                          />
                        }
                        size="small"
                        sx={{ backgroundColor: "#FEF3C7", color: "#92400E" }}
                      />
                    ) : (
                      <Chip
                        label={row.l2Status}
                        icon={
                          <CheckCircleIcon
                            style={{ color: "#4caf50", fontSize: "12px" }}
                          />
                        }
                        size="small"
                        sx={{ backgroundColor: "#DCFCE7", color: "#166534" }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <IconButton size="small">
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton size="small">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default WFA;