import React from 'react';
import { Box, Button, Typography } from '@mui/material';

interface ApprovalActionsProps {
  onApprove: () => void;
  onDecline: () => void;
}

const ApprovalActions: React.FC<ApprovalActionsProps> = ({ onApprove, onDecline }) => {
  return (
    <Box>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 1 }}>
      <Button
        // variant="contained"
        
        onClick={onDecline}
        sx={{ textTransform: 'none', minWidth: '80px', marginRight: 1, padding: '4px 8px', fontSize: '12px',background:"#FEF2F3", color:"#E8505B" }} 
      >
        Decline
      </Button>
      <Button
        variant="contained"
        color="success"
        onClick={onApprove}
        sx={{ textTransform: 'none', minWidth: '80px', padding: '4px 8px', fontSize: '12px' }} 
      >
        Approve
      </Button>
      
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end'}} >
    <Typography color="textSecondary" sx={{ fontSize: '10px', color: "#8C8C8C", mt:"5px" }}> one new Request for your Approvel</Typography>
    </Box>

    </Box>
  );
};

export default ApprovalActions;
