import { Avatar, Box, Button,  FormControl, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import TableContainer from '@mui/material/TableContainer';
import React, { useState } from "react";
import legendLogo from '../../../assets/images/legendLogo.svg';




const AssignRecruiter: React.FC = () => {
    const [isButtonClicked, setButtonClicked] = useState(false)

    const recruiterData = [
        {
            "name": "Delish Kumar R",
            "role": "Senior Architect",
            "currentRTHHolding": "5"
        },
        {
            "name": "Alice",
            "role": "Project Manager",
            "currentRTHHolding": "8"
        },
        {
            "name": "Bob",
            "role": "Software Engineer",
            "currentRTHHolding": "3"
        },
        {
            "name": "Catherine",
            "role": "UX Designer",
            "currentRTHHolding": "7"
        },
        {
            "name": "David",
            "role": "Product Owner",
            "currentRTHHolding": "6"
        }
    ]


    const [age, setAge] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
    };

    const handleButtonClick = () => {
        setButtonClicked(true)
    }

    const handleButtonCancel = () => {
        setButtonClicked(false)
    }
    return (
        <Box >
            {!isButtonClicked &&
                (
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} flexDirection={'row'}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: '#8C8C8C' }}>Click "Assign Recruiter Lead" to allocate the 20 available positions.</Typography>
                        <Button variant="outlined" size="small" sx={{ border: '1.5px solid' }} onClick={handleButtonClick}>Assign Recruiter Lead</Button>
                    </Box>
                )}

            {
                isButtonClicked &&
                (
                    <Box>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: '#8C8C8C', marginBottom: '1.5%' }}>Total No of Position opened:</Typography>
                        <TableContainer component={Paper} sx={{ marginBottom: '1.5%', borderRadius: '0px', boxShadow: 'none' }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#F9F9F9' }}>
                                        <TableCell align="left">No. of Position</TableCell>
                                        <TableCell align="left">Assign Recruiter Lead</TableCell>
                                        <TableCell sx={{ alignContent: 'center', alignItems: 'center' }} align="center"> <span>Assigned Date</span> <img src={legendLogo} alt="" />  <span style={{ color: 'grey', fontSize: '10px' }}>assigned date can't be edited</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow

                                    >
                                        <TableCell align="left">10</TableCell>
                                        <TableCell align="left" sx={{ padding: '0'}} width={'50%'}>
                                            <FormControl fullWidth>                                                
                                                <Select
                                                    
                                                    id="demo-simple-select"
                                                    
                                                    value={age}
                                                    label="Choose Recruiter"
                                                    displayEmpty
                                                    onChange={handleChange}
                                                    renderValue={(selected)=>{
                                                        if(selected === '')
                                                        {
                                                            return <span style={{color:'grey'}}>Choose Recriuter</span>
                                                        }
                                                        const selectedRecruiter=recruiterData.find(data=>data.name === selected)

                                                        return (
                                                            <Box display={"flex"} alignItems="center"  gap={1} borderRadius={'5px'}  border={'1px solid grey'} width={'fit-content'} padding={'5px'} >
                                                                <Avatar sizes="small" alt={selectedRecruiter?.name} src="/static/images/avatar/2.jpg" />
                                                                <Box display={'flex'} flexDirection={'column'}>
                                                                    <Box display='flex' flexDirection={'row'}  alignItems={'center'} gap={0.5}>
                                                                    <Typography variant="h6" sx={{ fontSize: '12px', color: 'black' }}>
                                                                        {selectedRecruiter?.name}
                                                                    </Typography>
                                                                    <Typography variant="body2" sx={{ fontSize: '12px', color: '#8C8C8C' }}>
                                                                        {selectedRecruiter?.role}
                                                                    </Typography>
                                                                    </Box>
                                                                
                                                                    <Typography variant="body2" sx={{ fontSize: '12px', color: '#8C8C8C' }}>
                                                                        Current RTH Holding: <span style={{color:"black"}}>{selectedRecruiter?.currentRTHHolding}</span> 
                                                                    </Typography>
                                                                    </Box>                                                                
                                                            </Box>
                                                        );

                                                    }
                                                
                                                }

                                                    
                                                    

                                                    sx={{
                                                        color: 'grey',
                                                        '& .MuiSelect-select': {
                                                            border: 'none', // Removes border around selected value
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none', // Removes border around the Select component
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none', // Ensures border is removed on hover
                                                        },
                                                        '& .MuiSelect-iconOutlined': {
                                                            color: 'inherit', // Adjusts icon color if needed
                                                            border: 'none', // Removes border around the icon if needed
                                                        },
                                                    }}
                                                >
                                                    {recruiterData.map((data, index) => (
                                                        <MenuItem  key={index} value={data.name} sx={{ display: 'flex', alignItems: 'center'}}>
                                                            <Box display="flex" alignItems="center" width="100%">
                                                                <Box display="flex" gap={2} alignItems="center">
                                                                    <Avatar sizes="small" alt={data.name} src="/static/images/avatar/2.jpg" />
                                                                    <Box display="flex" flexDirection="column">
                                                                        <Typography variant="h6" sx={{ fontSize: '12px', color: 'black', marginBottom: '0.5em' }}>
                                                                            {data.name}
                                                                        </Typography>
                                                                        <Typography variant="body2" sx={{ fontSize: '10px', color: '#8C8C8C' }}>
                                                                            {data.role}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>

                                                                <Box sx={{ backgroundColor: '#427BC51A', borderRadius: '10px', padding: '2px 8px', marginLeft: 'auto' }}>
                                                                    <Typography variant="body2" sx={{ fontSize: '12px', color: '#8C8C8C' }}>
                                                                        Current RTH Holding: {data.currentRTHHolding}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="left" sx={{ color: '#C4CDD5' }}>-</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box display={"flex"} justifyContent={'end'} gap={3}>
                            <Button variant="text" sx={{ color: 'grey' }} onClick={handleButtonCancel}>Cancel</Button>
                            <Button variant="contained" color="primary">Submit</Button>
                        </Box>
                    </Box>
                )
            }
        </Box>
    )
}

export default AssignRecruiter;