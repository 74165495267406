import { Box } from '@mui/material';
import { TabsPanelNew } from './commoncomponent/TabpannelNew';




const Dashboard = () => {
    
    return (
        <Box>
            {/* <Box sx={{ backgroundColor: '#f0f4ff',  }}>
                <HeaderBar />
            </Box> */}
            <Box sx={{ flexDirection: 'row', flex: 1, display: "flex" }}>
                {/* <FilterSidebar /> */}
                {/* <TabsPanel /> */}
                <TabsPanelNew/>
                {/* <ViewCard/> */}
            </Box>
        </Box>
    )
}

export default Dashboard;