
import axios from 'axios';
import { RTH_API_URL } from '../../constants/Crudurl';






// // Async thunk for submitting the form data
// export const submitRthForm = createAsyncThunk(
//   'rth/submitRthForm',
//   async (formData: any, { rejectWithValue }) => {
//     console.log("formData",formData);

//     try {
//       const response = await axios.post('https://mytheron-dev-backend.citpl.info/rth/addRth', formData);
//       console.log("response.data",response.data)
//       return response.data;
//     } catch (error: any) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );
// getRthData(id).then((res)=>dispatch(rthGetData(res.data))).catch((err)=>console.log(err))

export const getRthData = async (id: string) => {
  const token = localStorage.getItem('token');
  // console.log('token', token);

  try {
    const response = await axios.get(
      `https://mytheron-dev-backend.citpl.info/rth/viewRthByUserId/${id}}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};


//https:mytheron-dev-backend.citpl.info/master/getBusinessList

export const getRthBusinessInputData = async () => {
  const token = localStorage.getItem('token');
  // console.log('token', token);

  try {
    const response = await axios.get(
      `${RTH_API_URL}/getBusinessList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    console.error('getRthData', error);

    return error.response?.data || { message: 'An unknown error occurred' };
  }
};





export const submitRthForm = async (data: any) => {
    const token = localStorage.getItem("token");
// console.log("token",token);
    try {
        const response = await axios.post('https://mytheron-dev-backend.citpl.info/rth/addRth', data,
            {
                headers: { Authorization: `Bearer ${token}` },
                
            },
          )

        // console.log('response.data', response.data)
        return response.data
    } catch (error) {
        console.error("submitRthForm", error)
    }

}
// const rthSlice = createSlice({
//   name: 'rth',
//   initialState,
//   reducers: {
//     resetState: (state) => {
//       state.loading = false;
//       state.error = null;
//       state.success = false;
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(submitRthForm.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//         state.success = false;
//       })
//       .addCase(submitRthForm.fulfilled, (state) => {
//         state.loading = false;
//         state.success = true;
//       })
//       .addCase(submitRthForm.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload as string;
//       });
//   },
// });

// export const { resetState } = rthSlice.actions;
// export default rthSlice.reducer;
