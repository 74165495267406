import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export interface Job {
    department?: string;
    typeOfRth?: string;
    modeOfRth?: string;
    posted?: string;
    business?: any;
    tabStatus?: string;
    image?: string;
    closeImage?: string;
    name?: string;
    reqName?: string;
    moveTo?: string;
    l1Status?: string;
    l2Status?: string;
    status?: string;
    priority?: string;
    rthId? : string;
    rthProcess?: string;
    id?: Number;
    level?: string;
    positionTitle?:string;
    businessName?:string;
  }

interface JobState {
  myRequest: Job[];
  inProgress: Job[];
  closed: Job[];
  myRquestCounts:Job[],
  loading: boolean;
  error: string | null;
  businessName: string | null; 

}

const initialState: JobState = {
  myRequest: [],
  inProgress: [],
  closed: [],
  myRquestCounts:[],
  loading: false,
  error: null,
  businessName: null,
};

const teamHeadFilterDto = {
  l1StatusPending: false,
  l1StatusDeclined: false,
  l2StatusPending: false,
  l2StatusApproved: false,
  internalOnRole: false,
  internalContract: false,
  externalOneTime: false,
  externalContract: false,
  priorityLow: false,
  priorityMedium: false,
  priorityHigh: false,
  rthStages: ["open","close"],
  fromYear: 0,
  fromMonth: 0,
  fromDay: 0,
  toYear: 0,
  toMonth: 0,
  toDay: 0,
  page: 0, // Set to default page, adjust as needed
  size: 10  // Set to default size, adjust as needed
};

export const getBussinessNameById = createAsyncThunk('jobs/getBusinessNameById', async (businessId: string) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`https://mytheron-dev-backend.citpl.info/master/getBusinessById/${businessId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error; // Ensure errors are thrown for rejection
  }
});

export const fetchJobs = createAsyncThunk(
    'jobs/fetchJobs',
    async (userId: string, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token'); 
        const response = await axios.post(
          `https://mytheron-dev-backend.citpl.info/homePage/teamHead/${userId}`,teamHeadFilterDto,
          {
            headers: {
              Authorization: `Bearer ${token}`, 
            },
          }
        );
                
        console.log(response.data)
        return response.data.data;
      } catch (error: any) {
        return rejectWithValue(error.response.data);
      }
    }
  );

const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.loading = false;
        state.myRequest = action.payload['MyRequest'];
        state.inProgress = action.payload['In Progress'];
        state.closed = action.payload['Closed'];
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getBussinessNameById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBussinessNameById.fulfilled, (state, action) => {
        state.loading = false;
        state.businessName = action.payload; // Assuming you want to store the business name
      })
      .addCase(getBussinessNameById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

  },
});

export default jobSlice.reducer;
