import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { UserData } from '../../components/admin/AddUserForm';

interface UserState {
  users: {
    data: UserData[];
    
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: UserState = {
  users: { data: [] },
  status: 'idle',
  error: null,
};

export const fetchUsers = createAsyncThunk('user/fetchUsers', async () => {
  const token = localStorage.getItem("token");
  const response = await axios.get('https://mytheron-dev-backend.citpl.info/auth/findUsers', {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
});

export const addUser = createAsyncThunk('user/addUser', async (userData: Partial<UserData>) => {
  const token = localStorage.getItem("token");
  //'https://mytheron-dev-backend.citpl.info/auth/register'
  const response = await axios.post('https://mytheron-dev-backend.citpl.info/auth/register', userData, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (userId: string) => {
    const token = localStorage.getItem("token");
    await axios.delete(`https://mytheron-dev-backend.citpl.info/auth/deleteUser/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return userId;
  });

  export const updateUser = createAsyncThunk('user/updateUser', async (userData: UserData) => {
    const token = localStorage.getItem("token");
    console.log("apiupdate", userData )
    const response = await axios.put(`https://mytheron-dev-backend.citpl.info/auth/editUser/${userData.id}`, userData, {
      headers: { Authorization: `Bearer ${token}` }
    });
    console.log("apiupdate2", response.data )
    return response.data; 
    
  }); 

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(addUser.fulfilled, (state, action: PayloadAction<UserData>) => {
        if (state.users.data) {
          state.users.data.push(action.payload);
        } else {
          state.users.data = [action.payload];
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users.data = state.users.data.filter((user: UserData) => user.id !== action.payload);
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<UserData>) => {
        const index = state.users.data.findIndex(user => user.id === action.payload.id);
        if (index !== -1) {
          state.users.data[index] = action.payload;
        }
      })
  },
});

export default userSlice.reducer;